@use '../variables';
@use '../mixins';

/**
 * #.# Editor Styles
 *
 * CSS for just Backend enqueued after style.scss
 * which makes it higher in priority.
 */

@include mixins.scope-to-editor {
  .m-icon-text {
    display: flex;
    background-color: variables.$white;
    margin: 1em;
    padding: 1em;

    &__image {
      flex: 0 0 160px;
      align-self: flex-start;
      margin-right: 1em;
    }

    &__body {
      flex: 1 1 auto;
    }

    figure {
      text-align: center;
    }
  }
}
