@use '../variables';
@use '../mixins';

.wp-block-group {
  padding: 0.5rem 1rem;
  // border: 1px dashed variables.$wp_blue;
  //
  // .is-selected > & {
  //   border: none;
  // }
  //
  // &::before {
  //   content: "GROUP";
  //   @include mixins.element-label;
  // }
}

.m-group {
  &.a-bg-seafoam {
    background-color: variables.$seafoam;
  }
}
