@use 'variables';
@use 'mixins';

@include mixins.scope-to-editor {
  .wp-block {
    max-width: 800px;
  }

  .cic-element-label {
    @include mixins.element-label;
    display: block;
    border-bottom: 1px solid variables.$wp_blue;

    &--end {
      padding-top: 3px;
      border-top: 1px solid variables.$wp_blue;
      border-bottom: none;
    }
  }

  // Disable advanced panel
  .editor-block-inspector__advanced {
    display: none;
  }

  .cic-image-button {
    height: auto;
  }

  .m-button {
    padding: 10px;
    text-decoration: none;
    pointer-events: none;

    &--magenta {
      color: variables.$white;
      background-color: variables.$magenta;
    }

    &--dark-blue {
      color: variables.$white;
      background-color: variables.$dark-blue;
    }

    &--white-magenta {
      color: variables.$magenta;
    }
  }

  .is-style-thin {
    font-weight: 400;
  }
}
