$black: rgb(41, 41, 41);
$green: #bada55;
$red: orangered;
$seafoam: #f2f8f5;
$white: #ffffff;
$wp_blue: #007cba;
$magenta: #9b326e;
$dark-blue: #006072;

$wp_font: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen-sans,
  ubuntu, cantarell, "Helvetica Neue", sans-serif;

$breakpoint80: 850px;

@mixin block {
  padding: 10px;
  margin: 3px;
  background-color: rgba(0, 0, 0, 0.05);
}
