@use 'variables';

@mixin element-label {
  font-family: variables.$wp_font;
  font-size: 0.75rem;
  color: variables.$wp_blue;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.5em;
}

@mixin scope-to-editor {
  .wp-admin {
    @content;
  }
}
