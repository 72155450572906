@use "../variables";
@use '../mixins';

@include mixins.scope-to-editor {
  .m-testimonial {
    &__inner {
      display: flex;
      max-width: 1280px;
    }

    &__image,
    &__body {
      width: 50%;
    }

    &__image {
      display: flex;
      align-items: center;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 40px;
      padding-left: 70px;
    }

    &__quote {
      font-style: italic;
    }

    &__citation {
      font-weight: bold;
    }

    figure {
      margin: 0;
    }

    @media screen and (max-width: variables.$breakpoint80) {
      &__inner {
        flex-direction: column;
      }

      &__image,
      &__body {
        width: 100%;
      }

      .cic-image-button {
        margin: auto;
      }
    }
  }
}
