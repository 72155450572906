@use '../variables';
@use '../mixins';

@include mixins.scope-to-editor {
  .cic-pfml-blocks-callout {
    .wp-block-image {
      margin: 0;
    }

    &__button {
      display: block;
      margin-top: 1em;
    }

    @media (min-width: 600px) {
      .block-editor-url-input input[type="text"] {
        width: 100%;
      }
    }
  }
}
