@use '../variables';
@use '../mixins';

@include mixins.scope-to-editor {
  .cic-pfml-blocks-two-columns {
    .block-editor-inner-blocks {
      width: 100%;
    }

    > .block-editor-inner-blocks > .block-editor-block-list__layout {
      display: flex;

      > * {
        flex: 1;
      }
    }

    &__child {
      &--bordered {
        padding-left: 1em;
        border-left: 3px solid variables.$black;
      }
    }
  }
}
