@use '../mixins';

@include mixins.scope-to-editor {
  .cic-pfml-blocks-callout-grid {
    &__heading {
      text-align: center;
    }

    .block-editor-block-list__layout {
      /* autoprefixer grid: off */
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 1em;

      > .wp-block {
        width: 100%;
      }
    }
  }
}
