@use '../mixins';

@include mixins.scope-to-editor {
  .m-cta {
    &__text {
      margin: 50px;
    }

    &--one-column &__text {
      text-align: center;
    }

    &--two-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }

    a {
      white-space: nowrap;
    }
  }
}
