@use '../variables';
@use '../mixins';

@include mixins.scope-to-editor {
  .m-application-process {
    @include variables.block;

    &__header {
      text-align: center;
    }

    &__body {
      background: white;
    }

    &__images {
      @include variables.block;
    }

    [data-type='cic-pfml-blocks/image-uploads'] {
      .block-editor-block-list__layout {
        display: flex;
      }
    }

    &__field {
      @include variables.block;
      width: 100%;
    }
  }
}

